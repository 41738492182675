import React, { Fragment, FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { FluidObject } from 'gatsby-image'
import { Img } from '../components/FeatureImage/styled'
import Seo from '../components/Seo'
import { PageQuery } from '../graphqlTypes'
import Layout from '../components/Layout'
import ContentBox from '../components/ContentBox'

export const pageQuery = graphql`
  query Page($id: String) {
    markdownRemark(id: {eq: $id}) {
      id
      html
      frontmatter {
        slug
        tags
        title
        headerImage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`

interface Props {
  data: PageQuery
}

export const Page: FunctionComponent<Props> = ({ data: { markdownRemark: page } }: Props) => {
  const fluidHeaderImage = page?.frontmatter?.headerImage?.childImageSharp?.fluid as FluidObject
  return (
    <Fragment>
      <Seo title={page?.frontmatter?.title}/>
      <Layout>
        <ContentBox>
          { fluidHeaderImage !== null ? <Img fluid={fluidHeaderImage} /> : ''}
          <div dangerouslySetInnerHTML={{ __html: page?.html as string }} />
        </ContentBox>
      </Layout>
    </Fragment>
  )
}

export default Page
